import { GlobalState } from "little-state-machine";

const updateCheckoutState = (
  state: GlobalState,
  payload: Partial<GlobalState>
) => {
  return {
    ...state,
    ...payload,
  };
};

export default updateCheckoutState;
