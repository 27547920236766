import Plan, { PlanType } from "./components/Plan/Plan";

interface PlanSelectorProps {
  plans: PlanType[];
  onChange: (plan: PlanType) => void;
  selected: string | undefined;
  onPlanChosen: any;
}

const PlanSelector: React.FC<PlanSelectorProps> = ({
  plans,
  selected,
  onChange,
  onPlanChosen,
}) => {
  const max = (arr: any[]) => {
    return Math.max(...arr);
  };

  return (
    <div className="grid md:grid-cols-2 gap-8">
      {plans.map((plan, index) => {
        return (
          <Plan
            key={plan.id}
            selected={plan.id === selected}
            onClick={() => onChange(plan)}
            isAnnual={
              plan.price === max(plans.map((item) => item.price)) ? true : false
            }
            onPlanChosen={onPlanChosen}
            {...plan}
          />
        );
      })}
    </div>
  );
};

export default PlanSelector;
