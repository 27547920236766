export interface GetCouponRequest {
  couponId: string;
}

interface CreateUserResponse {
  clientSecret: string;
  customerId: string;
}

const noCoupon = {
  couponId: "No Coupon",
};

localStorage.setItem("couponDetails", JSON.stringify(noCoupon));

export const getCoupon = async (coupon: GetCouponRequest): Promise<any> => {
  console.log(coupon, "coupon1");
  localStorage.setItem("couponDetails", JSON.stringify(coupon));

  const request = await fetch("/api/get-coupon", {
    method: "POST",
    body: JSON.stringify(coupon),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!request.ok) {
    const resp = (await request.json()) as any;
    throw new Error(resp?.error || `Coupon not existed`);
  }

  return request.json();
};
