import classNames from "classnames";
import { HTMLAttributes } from "react";

interface TypographyProps extends HTMLAttributes<HTMLElement> {
  appearance?: "text" | "muted" | "error";
}

const Typography: React.FC<TypographyProps> = ({
  className,
  appearance,
  ...props
}) => (
  <p
    className={classNames(className, {
      "text-stone-500 text-sm": appearance === "muted",
      "text-red-500": appearance === "error",
    })}
    {...props}
  />
);

Typography.defaultProps = {
  appearance: "text",
};

export default Typography;
