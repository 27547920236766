export interface CreateUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  credentials: string;
  priceId: string;
  affiliateId: string | number | null;
  coupon?: string;
}

interface CreateUserResponse {
  clientSecret: string;
  customerId: string;
  subscriptionId: string;
}

export const createStripeUser = async (
  user: CreateUserRequest
): Promise<CreateUserResponse> => {
  const request = await fetch("/api/create-stripe-user", {
    method: "POST",
    body: JSON.stringify(user),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!request.ok) {
    const resp = (await request.json()) as any;
    throw new Error(resp?.error || `Failed to create user`);
  }

  return request.json();
};
