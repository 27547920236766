import { GlobalState, useStateMachine } from "little-state-machine";
import { useCallback, useEffect, useMemo } from "react";
import SlideDown from "react-slidedown";
import updateCheckoutState from "../../../../shared/utilities/updateCheckoutState";
import StepHeader from "../StepHeader";
import ChoosePlan from "./components/ChoosePlan";
import Confirmation from "./components/Confirmation";
import Payment from "./components/Payment";
import PersonalDetails from "./components/PersonalDetails";
import UserInfo from "./components/UserInfo";

import PencilIcon from "../../../../assets/images/pencil.svg";
import { useTranslation } from "react-i18next";

type OnStepCompletedFn = (body: Partial<GlobalState>) => void;
export interface StepProps {
  step: number;
  onStepCompleted: OnStepCompletedFn;
}

const Steps: React.FC = () => {
  const { state, actions } = useStateMachine({
    updateCheckoutState,
  });
  const { t } = useTranslation();

  const steps = useMemo(
    () => [
      {
        name: t("Choose Your Plan"),
        component: ChoosePlan,
      },
      {
        name: t("Your e-mail"),
        component: UserInfo,
      },
      {
        name: t("Personal details"),
        component: PersonalDetails,
      },
      {
        name: t("Payment"),
        component: Payment,
      },
      {
        name: t("Confirmation"),
        component: Confirmation,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const currentStep = state.step || 1;

  /**
   * scroll to active step on change
   */

  useEffect(() => {
    if (currentStep > 1) {
      setTimeout(
        () =>
          document.querySelector("#step-" + currentStep)?.scrollIntoView({
            // block: "center",
            // inline: "center",
            behavior: "smooth",
          }),
        500
      );
    }
  }, [currentStep]);
  /**
   * Callback handler when step completed
   */

  const onStepCompleted: OnStepCompletedFn = useCallback(
    (body) => {
      const nextStep = currentStep + 1;

      actions.updateCheckoutState({ ...body, step: nextStep });
    },
    [actions, currentStep]
  );

  const renderSteps = useMemo(
    () =>
      steps.map(({ name, component: Component }, index) => {
        const step = index + 1;
        const isActive = step === currentStep;

        return (
          <section
            id={`step-${currentStep}`}
            className={isActive ? "mb-20" : "mb-5"}
            key={index}
          >
            <StepHeader
              step={index + 1}
              active={isActive}
              currentStep={currentStep}
            >
              {name}
              {currentStep > step && currentStep < 4 && (
                <img
                  className="h-5 w-5 ml-2 cursor-pointer"
                  alt="edit"
                  src={PencilIcon}
                  onClick={() => {
                    actions.updateCheckoutState({ step });
                  }}
                />
              )}
            </StepHeader>
            <SlideDown transitionOnAppear={currentStep !== 1}>
              {isActive ? (
                <Component step={index} onStepCompleted={onStepCompleted} />
              ) : null}
            </SlideDown>
          </section>
        );
      }),
    [steps, onStepCompleted, currentStep, actions]
  );

  return <>{renderSteps}</>;
};

export default Steps;
