import { useStateMachine } from "little-state-machine";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import Typography from "../../../../../../shared/components/Typography";
import { createStripeUser } from "../../../../../../shared/services/createStripeUser";
import { fetcher } from "../../../../../../shared/utilities/fetcher";
import updateCheckoutState from "../../../../../../shared/utilities/updateCheckoutState";
import PlanSelector from "../../../../components/PlanSelector";
import { StepProps } from "../../../../components/Steps/Steps";
import { PlanType } from "../../../PlanSelector/components/Plan/Plan";
import ReactGA from "react-ga4";
const ChoosePlan: React.FC<StepProps> = ({ onStepCompleted }) => {
  const { state, actions } = useStateMachine({
    updateCheckoutState,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { t } = useTranslation();

  const { data: plans } = useSWR<PlanType[]>("/api/prices", fetcher, {
    onSuccess: (data) => {
      if (!state.plan) {
        actions.updateCheckoutState({ plan: data[0] });
      }
    },
  });

  const onPlanChosen = async (data: PlanType) => {
    onStepCompleted({ step: 1 });
  };

  if (!plans) {
    return <>{t("Loading")}...</>;
  }
  const handlePlan = (plan: any) => {
    // tracking event for plan selection
    function addZeroes(num: String) {
      const dec = num.split(".")[1];
      const len = dec && dec.length > 2 ? dec.length : 2;
      return Number(num).toFixed(len);
    }
    ReactGA.event("1_select_plan_step", {
      category: "plan_selection",
      action: `step_plan_${plan.name}_selected_${addZeroes(
        (plan.price || "").toString()
      )}_${plan.currency}`,
      label: `${plan.price}${plan.currency} Plan Selected`,
      value: plan.price,
    });

    actions.updateCheckoutState({ plan });
  };
  return (
    <>
      <PlanSelector
        onChange={(plan) => handlePlan(plan)}
        plans={plans}
        selected={state.plan?.id}
        onPlanChosen={onPlanChosen}
      />
      {error && (
        <Typography appearance="error">
          Error:{" "}
          {error?.includes("Duplicate username")
            ? t("Account already registered. Try with a different email.")
            : error}
        </Typography>
      )}
    </>
  );
};

export default ChoosePlan;
