import generalTranslationsEn from "./en/general.json";
import generalTranslationsDa from "./da/general.json";
import generalTranslationsJa from "./ja/general.json";
import generalTranslationsEs from "./es/general.json";

const translations = {
  da: {
    general: generalTranslationsDa,
  },
  en: {
    general: generalTranslationsEn,
  },
  ja: {
    general: generalTranslationsJa,
  },
  es: {
    general: generalTranslationsEs,
  },
};

export default translations;
