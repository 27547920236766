import { t } from "i18next";
import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Input, { InputProps } from "../Input/Input";

interface PasswordInputProps extends InputProps {}

const Password = forwardRef<HTMLInputElement, PasswordInputProps>(
  (props, ref: any) => {
    const [isShowing, setIsShowing] = useState<Boolean>(false);

    const toggleVisibility = () => {
      setIsShowing(!isShowing);
    };
    const { t } = useTranslation();

    return (
      <Input
        ref={ref}
        type={isShowing ? "text" : "password"}
        inputWrapperClassName="relative w-full"
        {...props}
      >
        <div className="absolute inset-y-0 right-0 flex items-center px-2">
          <input
            className="hidden js-password-toggle"
            id="toggle"
            type="checkbox"
            onChange={toggleVisibility}
          />
          <label
            className="bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer select-none"
            htmlFor="toggle"
          >
            {isShowing ? t("hide") : t("show")}
          </label>
        </div>
      </Input>
    );
  }
);

export default Password;
