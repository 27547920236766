/* eslint-disable jsx-a11y/anchor-is-valid */
import { useStateMachine } from "little-state-machine";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Button from "../../../../../../shared/components/Button";
import Checkbox from "../../../../../../shared/components/Checkbox";
import Input from "../../../../../../shared/components/Input";
import Link from "../../../../../../shared/components/Link";
import Password from "../../../../../../shared/components/Password";
import { createStripeUser } from "../../../../../../shared/services/createStripeUser";
import { StepProps } from "../../../../components/Steps/Steps";
import updateCheckoutState from "../../../../../../shared/utilities/updateCheckoutState";
import ReactGA from "react-ga4";
export interface PersonalDetailsFormValues {
  firstName: string;
  lastName: string;
  password: string;
  acceptTerms: boolean;
}

const MinCharge: any = {
  JPY: 50,
  GBP: 0.3,
  USD: 0.5,
  EUR: 0.5,
  AUD: 0.5,
};

const getMinChargeForCountry = (currency: string) => {
  return MinCharge[currency.toUpperCase()] || 0.5;
};

const PersonalDetails: React.FC<StepProps> = ({ onStepCompleted }) => {
  const { state, actions } = useStateMachine({
    updateCheckoutState,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<PersonalDetailsFormValues>({
    mode: "onBlur",
    defaultValues: state.personalDetails,
  });

  const onSubmit = (data: PersonalDetailsFormValues) => {
    actions.updateCheckoutState({ personalDetails: data });

    if (state.plan) {
      setIsLoading(true);

      ReactGA.event("3_personal_information_step", {
        category: "personal_information_step",
        action: `step_personal_information_complete`,
        label: "Persona Information Entered",
        // value: 1,
      });

      createStripeUser({
        email: state.userInfo.email,
        firstName: data.firstName,
        lastName: data.lastName,
        credentials: data.password,
        priceId: state?.plan?.id,
        affiliateId: state.affiliateId,
        coupon: state.coupon,
      })
        .then(({ clientSecret, customerId, subscriptionId }) => {
          if (state.plan && state.detailCoupon) {
            let amount_percent =
              (state.plan?.price * (100 - state.detailCoupon.percent_off)) /
              100;
            let amout_off = state.plan?.price - state.detailCoupon.amount_off;
            let amount_payment = state.detailCoupon.percent_off
              ? amount_percent
              : amout_off;

            if (
              clientSecret === undefined &&
              amount_payment < getMinChargeForCountry(state.plan.currency)
            ) {
              window.location.href =
                process.env.REACT_APP_PUBLIC_URL +
                "/confirmation?redirect_status=succeeded";

              onStepCompleted({
                stripe: { clientSecret, customerId, subscriptionId },
              });
            } else {
              onStepCompleted({ step: 3 });
              onStepCompleted({
                stripe: { clientSecret, customerId, subscriptionId },
              });
            }
          } else {
            onStepCompleted({ step: 3 });
            onStepCompleted({
              stripe: { clientSecret, customerId, subscriptionId },
            });
          }
        })
        .catch((error) => {
          setError(t(error.message) || t("Unknown error"));
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, maybe you can't use this coupon or invalid email, please check!",
          });
        })
        .finally(() => setIsLoading(false));
    } else {
      throw new Error(t("No plan chosen"));
    }
  };

  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid md:grid-cols-2 gap-8">
        <Input
          type="text"
          label={t("First name")}
          required
          placeholder={t("e.g. John")}
          inputClassName="w-full max-w-full"
          autoComplete="given-name"
          error={errors.firstName}
          {...register("firstName", {
            required: {
              value: true,
              message: t("Required field"),
            },
            minLength: {
              value: 1,
              message: t("Must be minimum 1 character"),
            },
          })}
        />
        <Input
          type="text"
          label={t("Last name")}
          required
          placeholder={t("e.g. Smith")}
          inputClassName="w-full max-w-full"
          autoComplete="family-name"
          error={errors.lastName}
          {...register("lastName", {
            required: {
              value: true,
              message: t("Required field"),
            },
            minLength: {
              value: 2,
              message: t("Must be minimum 1 character"),
            },
          })}
        />
        <div>
          <Password
            label={t("Password")}
            required
            placeholder="abcDefg1"
            description={t(
              "Password must be 8 characters long and should include one number and one capital letter"
            )}
            inputClassName="w-full max-w-full"
            className="mb-4"
            autoComplete="new-password"
            error={errors.password}
            {...register("password", {
              required: {
                value: true,
                message: t("Required field"),
              },
              pattern: {
                value: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}/,
                message: t(
                  "Should be minimum 8 characters and contain one number and one capital letter"
                ),
              },
            })}
          />
          <Checkbox
            value={1}
            label={
              <Trans
                i18nKey="acceptTermsText"
                components={{
                  termsLink: (
                    <Link href={t("legalTermsLink")} target="_blank" />
                  ),
                  privacyPolicy: (
                    <Link href={t("legalTermsLink")} target="_blank" />
                  ),
                }}
              />
            }
            error={errors.acceptTerms}
            {...register("acceptTerms", {
              required: {
                value: true,
                message: t("Please accept our terms and conditions"),
              },
            })}
          />
          <Button type="submit" className="mt-5" id="B_signup_personalinfo">
            {t("Submit")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default PersonalDetails;
