import { useStateMachine } from "little-state-machine";
import { useForm } from "react-hook-form";
import { Stripe } from "stripe";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stringify } from "qs";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../shared/components/Button";
import Input from "../../../../../../shared/components/Input";
import Link from "../../../../../../shared/components/Link";
import { StepProps } from "../../../../components/Steps/Steps";
import ReactGA from "react-ga4";
export type UserInfoFormValues = {
  email: string;
};

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripeKey);

const Wrapper = (props: any) => (
  <Elements stripe={stripePromise}>
    <UserInfo {...props} />
  </Elements>
);

const UserInfo: React.FC<StepProps> = ({ onStepCompleted }) => {
  const { state } = useStateMachine();
  const stripe = useStripe();
  const elements = useElements();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserInfoFormValues>({
    defaultValues: state.userInfo,
  });
  async function fetchData<T>(
    endpoint: string,
    secretKey: string,
    body?: any,
    init: RequestInit = {}
  ): Promise<T> {
    init = {
      ...init,
      body: body ? stringify(body) : undefined,
      headers: {
        ...init.headers,
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${secretKey}`,
      },
    };
    const url = endpoint;
    // const url = STRIPE_API_URL + endpoint;

    const response = await fetch(url, init);

    if (!response.ok || response.status.toString()[0] !== "2") {
      throw new Error(
        `Stripe API call failed to ${endpoint} (${
          response.status
        }): ${JSON.stringify(await response.json())}`
      );
    }

    return response.json();
  }

  const onSubmit = async (data: UserInfoFormValues) => {
    //const key = "sk_test_51K9o9cCdiyMESuFXfl1iG4ocsYi3SCQzOnwkqQ26x5kvZdN5vdw5vQSZjOVWNxC0TeKRK2WnLi8BdbfZD5beZK6M00T2RBmS57" //DEV
    const key =
      "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";
    const inputData = data;
    //  Consumer - consumer remains the same
    await fetchData<{ data: any }>(
      `https://api.stripe.com/v1/customers?email=${data?.email}`,
      key
    ).then(({ data }) => {
      if (Array.isArray(data) && data.length > 0) {
        const customerId = data[0].id;
        fetchData<{ data: any }>(
          `https://api.stripe.com/v1/subscriptions?customer=${customerId}`,
          key
        )
          .then(({ data }) => {
            if (data[0]?.plan?.active) {
              window.location.href = "http://watch.ohjazz.tv/login";
            } else {
              if (inputData?.email?.length > 0) {
                ReactGA.event("2_email_step", {
                  category: "email_step",
                  action: "step_email_complete",
                  label: "Email Entered Correctly",
                  //value: 1,
                });
              }
              onStepCompleted({ userInfo: inputData });
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
        if (inputData?.email?.length > 0) {
          ReactGA.event("2_email_step", {
            category: "email_step",
            action: "step_email_complete",
            label: "Email Entered Correctly",
            //value: 1,
          });
        }
        onStepCompleted({ userInfo: inputData });
      }
    });
  };

  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        id={"B_signup_email"}
        type="email"
        placeholder={t("e.g. John@email.com")}
        autoComplete="email"
        label={t("E-mail")}
        required
        error={errors.email}
        {...register("email", {
          required: {
            value: true,
            message: t("Required field"),
          },
          pattern: {
            value: /\S+@\S+.\S+/,
            message: t("Invalid email"),
          },
        })}
      />
      <Button type="submit" className="mt-10">
        {t("Next")}
      </Button>
      <Link className="pl-5" href="http://watch.ohjazz.tv/login">
        {t("Already a member")}
      </Link>
    </form>
  );
};

export default Wrapper;
