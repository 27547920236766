import { StepProps } from "../../Steps";
import Button from "../../../../../../shared/components/Button";
import { useEffect, useRef, useState } from "react";
import { useStateMachine } from "little-state-machine";
import clearCheckoutState from "../../../../../../shared/utilities/clearCheckoutState";
import Typography from "../../../../../../shared/components/Typography";
import { useTranslation } from "react-i18next";
import { createAppUser } from "../../../../../../shared/services/createAppUser";
import ReactGA from "react-ga4";

const Payment: React.FC<StepProps> = () => {
  const { state, actions } = useStateMachine({
    clearCheckoutState,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const email = useRef(state.userInfo.email);

  const orderDetails = localStorage.getItem("orderDetails");
  const parseOrderDetails = orderDetails && JSON.parse(orderDetails);

  const couponDetails = localStorage.getItem("couponDetails");
  const parseCouponDetails = couponDetails && JSON.parse(couponDetails);

  // clear checkout state, so user can revisit form in same session
  useEffect(() => {
    // create the user

    if (state.userInfo.email) {
      setIsLoading(true);

      createAppUser({
        email: state.userInfo.email,
        firstName: state.personalDetails.firstName,
        lastName: state.personalDetails.lastName,
        credentials: state.personalDetails.password,
        affiliateId: state.affiliateId,
      })
        .then((user) => {
          console.log("Created user", user);
        })
        .catch((error) => setError(t(error.message) || t("Unknown error")))
        .finally(() => setIsLoading(false));
    }

    // clear any checkout state
    actions.clearCheckoutState({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  if (isLoading) {
    return <>{t("Loading")}...</>;
  }

  return (
    <div className="text-center" id="confirmation-step">
      <h1 className="text-3xl text-center mb-5 font-extrabold">
        {t("Welcome to the Oh! Jazz Family.")}
      </h1>
      <h2 className="text-xl text-center mb-5 font-extrabold">
        {t(
          "Your subscription will empower clubs, musicians and artists all over the world."
        )}
      </h2>
      <Typography className="muted">
        {t("An email has been sent to your e-mail {{email}}", {
          email: email.current,
        })}
      </Typography>
      <Button
        id="B_signup_confirmation"
        className="mt-10"
        onClick={() => (window.location.href = "https://watch.ohjazz.tv")}
      >
        {t("Watch now")}
      </Button>
      {parseOrderDetails &&
        parseOrderDetails?.id &&
        parseOrderDetails?.amount && (
          <img
            src={`https://www.shareasale.com/sale.cfm?tracking=${
              parseOrderDetails.id
            }&currency=${parseOrderDetails.currency.toUpperCase()}&couponcode=${
              parseCouponDetails.couponId
            }&amount=$${
              parseOrderDetails.amount / 100
            }&merchantID=129607&transtype=sale`}
            width="1"
            height="1"
          />
        )}
      {error && <Typography appearance="error">Error: {error}</Typography>}
    </div>
  );
};

export default Payment;
