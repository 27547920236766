import { GlobalState } from "little-state-machine";
import { initialState } from "../store/checkout";

const clearCheckoutState = (
  state: GlobalState,
  payload: Partial<GlobalState> = {}
) => {
  return { ...initialState, affiliateId: state.affiliateId, ...payload };
};

export default clearCheckoutState;
