/* eslint-disable jsx-a11y/anchor-has-content */
import classNames from "classnames";
import { AnchorHTMLAttributes } from "react";

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {}

const Button: React.FC<LinkProps> = ({ className, ...props }) => (
  <a className={classNames(className, "text-cyan-500")} {...props} />
);

export default Button;
