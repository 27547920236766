import classNames from "classnames";
import { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  appearance?: "primary" | "secondary";
  loading?: boolean;
}

const loadingSpinner = (
  <svg
    className="animate-spin mr-3 h-5 w-5"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

const Button: React.FC<ButtonProps> = ({
  className,
  appearance,
  loading,
  children,
  ...props
}) => (
  <button
    className={classNames(
      className,
      "inline-flex items-center justify-center w-full md:w-60 rounded-full text-black font-bold transition-colors duration-300 px-4 py-4",
      {
        "bg-white hover:bg-slate-100 text-darkblue hover:text-watermelon":
          appearance === "secondary",
        "bg-watermelon text-white": appearance === "primary",
        "cursor-progress": loading,
      }
    )}
    disabled={loading}
    {...props}
  >
    {loading && loadingSpinner}
    {children}
  </button>
);

Button.defaultProps = {
  appearance: "secondary",
};

export default Button;
