export interface SubcriptionProp {
  subcriptionId: string;
  customerId: string;
  payment_method: string;
}

export const updateStripeSub = async (body: SubcriptionProp): Promise<any> => {
  const request = await fetch("/api/update-subcription", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!request.ok) {
    const resp = (await request.json()) as any;
    throw new Error(resp?.error || `Failed to update subscription`);
  }

  return request.json();
};
