import classNames from "classnames";
import { forwardRef, ReactElement } from "react";
import { FieldError } from "react-hook-form";
import Label from "../Label";
import Typography from "../Typography";

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string | ReactElement;
  required?: boolean;

  htmlId?: string;

  description?: string;

  error?: FieldError;
}

const Checkbox = forwardRef<HTMLInputElement, InputProps>(
  ({ label, className, error, ...props }, ref) => {
    const labelFor = props.id || props.name;

    if (label && !props.id) {
      props.id = props.name || props.htmlId;
    }

    return (
      <>
        <div className="flex items-center">
          <input
            type="checkbox"
            className={classNames(
              "bg-stone-800 p-3 max-w-sm cursor-pointer",
              className
            )}
            ref={ref}
            {...props}
          />

          {label && (
            <Label forInput={labelFor} className="ml-4">
              {label}
            </Label>
          )}
        </div>

        {error && (
          <Typography appearance="error" className="text-sm">
            {error.message}
          </Typography>
        )}
      </>
    );
  }
);

export default Checkbox;
