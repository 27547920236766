import { useStateMachine } from "little-state-machine";
import React, { useEffect } from "react";

import "react-slidedown/lib/slidedown.css";
import updateCheckoutState from "../../shared/utilities/updateCheckoutState";
import Steps from "./components/Steps";

import logo from "../../assets/images/Oh_jazz_white.svg";
import { useTranslation } from "react-i18next";

const SignupForm: React.FC = () => {
  const { state, actions } = useStateMachine({
    updateCheckoutState,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    /* on completed */
    if (
      queryParams.get("redirect_status") === "succeeded" &&
      state.step !== 5
    ) {
      actions.updateCheckoutState({ step: 5 });
    } else if (!state.step) {
      actions.updateCheckoutState({ step: 1 });
    }

    /* store affiliate ids */
    if (queryParams.get("affiliate")) {
      actions.updateCheckoutState({
        affiliateId: queryParams.get("affiliate"),
      });
    }
  }, [actions, state.step]);

  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center w-full max-w-4xl	mx-auto pt-10">
      <div className="mx-5">
        <div className="items-center flex flex-col">
          <img src={logo} height="100" width="100" alt="oh-jazz" />
          <h1 className="text-6xl tracking-wider text-watermelon text-center mt-10 mb-20 font-extrabold font-heading">
            {t("Create your account")}
          </h1>
        </div>
        <Steps />
      </div>
    </div>
  );
};

export default SignupForm;
