/* eslint-disable jsx-a11y/anchor-has-content */
import classNames from "classnames";
import { LabelHTMLAttributes } from "react";

interface LabelProps extends LabelHTMLAttributes<HTMLAnchorElement> {
  forInput?: string;
}

const Label: React.FC<LabelProps> = ({
  className,
  children,
  forInput,
  ...props
}) => (
  <label className={classNames(className, "cursor-pointer")} htmlFor={forInput}>
    {children}
  </label>
);

export default Label;
