interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: any;
}

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : children || null);

export default ConditionalWrapper;

/**
 * Example:
 * 
 *           <ConditionalWrapper
                condition={hasSlider && boxes.length > 0}
                wrapper={(children) => (
                  <Slider {...sliderSettings}>{children}</Slider>
                )}
              >
                  <div>Some children, with or without Slider</div>
                  <div>Some children, with or without Slider</div>
                  <div>Some children, with or without Slider</div>
              </ConditionalWrapper>
 */
