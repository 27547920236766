import classNames from "classnames";
import { forwardRef } from "react";
import { FieldError } from "react-hook-form";
import ConditionalWrapper from "../ConditionalWrapper";
import Label from "../Label";
import Typography from "../Typography";

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  required?: boolean;
  htmlId?: string;
  description?: string;
  error?: FieldError;
  inputClassName?: string;
  inputWrapperClassName?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      className,
      inputClassName,
      error,
      children,
      inputWrapperClassName,
      description,
      ...props
    },
    ref
  ) => {
    const labelFor = props.id || props.name;

    if (label && !props.id) {
      props.id = props.name || props.htmlId;
    }

    return (
      <div className={classNames("flex flex-col", className)}>
        {label && (
          <Label forInput={labelFor} className="mb-4">
            {label}
          </Label>
        )}
        <ConditionalWrapper
          condition={!!inputWrapperClassName}
          wrapper={(children: any) => (
            <div className={inputWrapperClassName}>{children}</div>
          )}
        >
          <input
            className={classNames("bg-stone-800 p-3 max-w-sm", inputClassName)}
            ref={ref}
            {...props}
          />
          {children}
        </ConditionalWrapper>
        {error && (
          <Typography appearance="error" className="text-sm mt-2">
            {error.message}
          </Typography>
        )}
        {description && (
          <Typography appearance="muted" className="text-sm mt-2">
            {description}
          </Typography>
        )}
      </div>
    );
  }
);

export default Input;
