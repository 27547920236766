import { StripeElementsOptions } from "@stripe/stripe-js";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useStateMachine } from "little-state-machine";
import updateCheckoutState from "../../../../../../shared/utilities/updateCheckoutState";
import PaymentForm from "../../../../components/PaymentForm";
import { StepProps } from "../../../../components/Steps/Steps";

import SecurePaymentImage from "../../../../../../assets/images/secure-stripe.png";
import Link from "../../../../../../shared/components/Link";
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
    "pk_test_51K9o9cCdiyMESuFXhu9BIEmWaVFhQQNLYb7mXlb0cVmiZWNAS68wvuYBOU0PKVbzLgZYHJwgkxOTEGkKXu0lhJ9I00xvcPUZZz"
);

const Payment: React.FC<StepProps> = () => {
  const { state } = useStateMachine({
    updateCheckoutState,
  });

  const { t } = useTranslation();

  if (!state.stripe.clientSecret) {
    return null;
  }

  const options: StripeElementsOptions = {
    clientSecret: state.stripe.clientSecret,
    appearance: {
      theme: "night",
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm />
      <Link href="https://stripe.com" target="_blank">
        <img
          src={SecurePaymentImage}
          width={350}
          className="mt-10"
          alt={t("Powered by Stripe")}
        />
      </Link>
    </Elements>
  );
};

export default Payment;
