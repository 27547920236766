import { StateMachineProvider, createStore } from "little-state-machine";

export const initialState = {
  affiliateId: null,
  step: 0,
  country: "",
  userInfo: {
    email: "",
  },
  personalDetails: {
    firstName: "",
    lastName: "",
    password: "",
    acceptTerms: false,
  },
  plan: null,
  stripe: {
    customerId: undefined,
    clientSecret: undefined,
    subcriptionId: undefined,
  },
};

createStore(initialState, {});

export const StoreProvider = StateMachineProvider;
