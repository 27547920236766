import c, { Options } from "currency.js";

export type PriceProps = {
  amount?: string | number;
  children: string | number;
  currency: string;
  zeroValue?: string;
  showDecimals?: boolean;
};

const Price: React.FC<PriceProps> = ({
  amount,
  children,
  currency,
  zeroValue,
  showDecimals = false,
}) => {
  const priceAmount = amount || children;

  const format = () => {
    const options = {
      formatWithSymbol: true,
      // pattern: "# !",
      negativePattern: "-# !",
    } as Options;

    switch (currency.toLocaleUpperCase()) {
      case "JPY":
        options.symbol = "¥";
        break;
      case "GBP":
        options.symbol = "£";
        break;
      case "EUR":
        options.symbol = "€";
        break;
      case "AUD":
      case "USD":
        options.symbol = "$";
        break;
    }

    if (!amount && zeroValue) {
      return zeroValue;
    }

    return c(priceAmount, options).format();
  };

  return (
    <span className="text-4xl uppercase pt-6 text-[#ea5a54]">{format()}</span>
  );
};

export default Price;
